<template>
    <div>
        USER: {{ user }}
        TOKEN: {{ token }}
    </div>
</template>

<script>
export default {
    name: "auto-login",
    data(){
        return {
            user: this.$route.params.user,
            token: this.$route.params.token
        }
    },
    created() {
        sessionStorage.username = this.user
        sessionStorage.token = this.token
        this.$emit('login', this.user);
    }
}
</script>
